<script setup lang="ts">

import { useField } from "vee-validate";
import {cn} from "~/lib/utils";

const props = defineProps({
    items: {
        type: Array,
        required: true
    },
    name: {
        type: String,
        default: 'tags'
    },
    max: {
        type: Number,
        default: 4
    },
    rules: {
        type: String,
        default: ''
    },
    immutable: {
        type: Boolean,
        default: false
    },
    itemClass: {
        type: String,
        default: ''
    },
    optionName: {
        type: String,
        default: 'name',
    },
    optionValue: {
        type: String,
        default: 'documentId',
    },
})

const model = defineModel<any>({
    default: []
});

function isSelected(id: string): boolean {
    return model.value?.some((item: any) => item === id);
}

function toggleItem(item: any): void {
    if (props.immutable) return;
    const index = model.value?.findIndex((itm: any) => itm === item[props.optionValue]);
    if (index === -1 || index === undefined || index === null) {
        if (!Array.isArray(model.value)) {
            model.value = [];
        }
        if (props.max > 0 && model.value?.length >= props.max) {
            return;
        }
        model.value.push(item[props.optionValue]);
    } else {
        model.value.splice(index, 1);
    }
}
</script>

<template>
    <div class="flex flex-wrap gap-2 w-fit">
        <UiBadge
            @click="toggleItem(item)"
            v-for="item in items"
            :class="cn('spec-button text-xs rounded-lg bg-transparent text-primary-800 border-primary-900 font-medium w-fit text-nowrap hover:bg-primary-50 py-1 px-2', itemClass, { 'selected': isSelected(item[optionValue]) || immutable, 'cursor-pointer': !immutable })"
            >
            {{ item[optionName] }}
        </UiBadge>
    </div>
</template>

<style scoped lang="scss">
.spec-button {
    border: 1px solid;

    &.selected {
        color: #fff;
        background-color: #2D9F75;
    }
}
</style>