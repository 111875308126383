<script lang="ts" setup>
import {useField} from 'vee-validate';
import {toTypedSchema} from "@vee-validate/zod";
import * as z from "zod";

const {parentSpecifications, childSpecifications} = useGlobalStore()

const model = defineModel<any>();

const props = defineProps({
    name: {
        type: String,
        default: 'specifications'
    },
});

const parentModel = computed(() => {
    return parentValue.value || parentSpecifications.find(item => item.documentId === model.value[0]?.parent_id)
})

const specificationChildList = computed(() => {
    if (parentModel.value) {
        return childSpecifications(parentModel.value)
    }
    return []
})

const fieldSchema = z.string({
    invalid_type_error: "Invalid specification",
})
    .array()
    .nonempty({
        message: "Choose at least one option",
    })
    .max(4)
const {value, errorMessage, setValue} = useField(props.name, toTypedSchema(fieldSchema));

const selectedParentSpecification = ref()
const { value: parentValue, setValue: setParentValue } = useField('parent_specification', toTypedSchema(
    z.string({
        required_error: "Invalid main specification",
        invalid_type_error: "Invalid main specification",
    })));

watch(
    selectedParentSpecification,
    (newValue) => {
        setParentValue(newValue, true);
    },
);

watch(
    model,
    (newValue) => {
        setValue(newValue, true);
    },
    {immediate: true, deep: true}
);

watch(() => parentValue, () => {
    model.value = [];
})

</script>

<template>
    <div>
        <UiFormField v-slot="{ componentField, meta }" name="parent_specification" v-model="selectedParentSpecification">
            <UiFormItem class="relative w-full">
                <UiFormControl>
                    <UiSelect v-bind="componentField">
                        <UiSelectTrigger
                            class="w-full !ring-0 !ring-offset-0 rtl:flex-row-reverse justify-between font-normal text-sub text-sm h-[46px] px-3 rounded-xl">
                            <div class="flex items-center rtl:flex-row-reverse">
                                <Icon name="ri:briefcase-2-line" size="20px" class="ltr:mr-2 rtl:ml-2 text-gray-500"/>
                                <UiSelectValue :placeholder="$t('Choose your specialty') + ' *'" :class="{
                                    'text-neutral-400': !selectedParentSpecification,
                                    'text-black': !!selectedParentSpecification,
                                }"/>
                            </div>
                        </UiSelectTrigger>
                        <UiSelectContent>
                            <UiSelectItem v-for="item in parentSpecifications" :value="item.documentId" class="hover:bg-stroke">
                                {{ item.name }}
                            </UiSelectItem>
                        </UiSelectContent>
                    </UiSelect>
                </UiFormControl>
                <UiFormMessage/>
            </UiFormItem>
        </UiFormField>

        <div class="mt-4" v-show="specificationChildList.length">
            <UiFormField v-slot="{ componentField, meta }" :name="props.name" >
                <div >
                    <custom-ui-tag-buttons
                        v-model="model"
                        :max="4"
                        :items="specificationChildList"
                        itemClass="!border-gray-200 text-gray-500 hover:bg-stroke"/>
                </div>
                <UiFormMessage class="mt-2" />
            </UiFormField>
        </div>
    </div>
</template>