# CustomUiInputFullName.vue
<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import * as z from "zod";
import { useField } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { cn } from "~/lib/utils";

const model = defineModel<string>();
const { t } = useI18n()
const props = defineProps({
    disabled: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
    },
    class: {
        type: String as PropType<HTMLAttributes['class']>
    },
    input_class: {
        type: String as PropType<HTMLAttributes['class']>
    },
    required: {
        type: Boolean,
        default: true
    },
    name: {
        type: String,
        default: 'full_name'
    },
    initialValue: {
        type: String,
        default: ''
    }
})

const usedPlaceholder = computed(() => {
    return props.placeholder || t('Your name')
})

const validateSchema = ref()
validateSchema.value =
    z.string({ message: 'Name is required' })
if (!props.required) {
    validateSchema.value = validateSchema.value.optional()
}

const { setValue } = useField(props.name, toTypedSchema(validateSchema.value));

onMounted(() => {
    if (props.initialValue) {
        setValue(props.initialValue, true);
        model.value = props.initialValue;
    }
});

watch(model, (val) => {
    setValue(val, true)
})

watch(() => props.initialValue, (newValue) => {
    if (newValue !== model.value) {
        setValue(newValue, true);
        model.value = newValue;
    }
}, {immediate: true});
</script>

<template>
    <UiFormField v-model="model" v-slot="{ componentField }" :name="name">
        <UiFormItem class="relative col-span-2">
            <UiFormLabel>
                <p class="mb-1">{{ $t("Full Name") }} <span v-if="required"
                                                            class="text-primaryblue-500">*</span></p>
            </UiFormLabel>
            <UiFormControl class="!mt-1">
                <UiInput icon="ri:user-3-line" :class="cn('ys-form-input ltr:pl-9 rtl:pr-9', props.input_class)"
                         :placeholder="usedPlaceholder" :disabled="disabled" v-bind="componentField" />
            </UiFormControl>
            <UiFormMessage />
        </UiFormItem>
    </UiFormField>
</template>